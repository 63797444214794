import React, { Fragment } from "react"; // So it doesn't create a unnecessary node element. **Only available in react 16+
import "@fontsource/unica-one"; // Defaults to weight 400
import Container from "react-bootstrap/Container";
import ButtonMailto from "../../components/buttonmail";
import Stack from "@mui/material/Stack";

export default function Contact() {
  //const [count, setCount] = useState(0)
  return (
    <Container fluid="true">
      <div className="contact">
        <div className="title">Contact Us</div>

        <div className="row">
          <div className="col-1 text"></div>
          <div className="col-3 text ">Investor inquiries:</div>
          <div className="col-6 link">
            <ButtonMailto
              label="investors@predator.ai"
              mailto="mailto:investors@predator.ai"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-1"></div>
          <div className="col-3 text">General inquiries:</div>
          <div className="col-6 link">
            <ButtonMailto
              label="questions@predator.ai"
              mailto="mailto:investors@predator.ai"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-1 text"></div>
          <div className="col-3 text">Careers:</div>
          <div className="col-6 link">
            <ButtonMailto
              label="careers@predator.ai"
              mailto="mailto:careers@predator.ai"
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

//import { useState } from 'react'
// import reactLogo from './assets/react.svg'
// import viteLogo from '/vite.svg'
import "@fontsource/silkscreen"; // Defaults to weight 400
//import "@fontsource/roboto"; // Specify weight
import "@fontsource/montserrat/500.css";
import "@fontsource/spline-sans-mono"; // Defaults to weight 400

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import "./../../css/home.css";
import React, { Fragment } from "react"; // So it doesn't create a unnecessary node element. **Only available in react 16+
import Container from "react-bootstrap/Container";

import Button from "@mui/material/Button";

function FullWidthGrid() {
  return (
    <Container fluid="true">
      <div className="hero">
        <div className="row">
          <div className="d-inline-flex flex-column">
            <div className="logo mx-auto">PREDATOR AI</div>

   
          </div>
        </div>
      </div>

      <div
        className="position-relative"
        style={{
          width: "100%",
          height: "25.0vw",
          // height: "500px",
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/sky.avif)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "grayscale(100%)",
        }}
      >
        <div className="hero2 position-absolute top-50 start-50 translate-middle text-center">
          {/* <div className="text">Owning the night, one flight at a time</div> */}
          <div className="text-white ">
            Owning the night, one flight at a time
          </div>
        </div>
      </div>

      <div className="hero">
        <div className="row">
          <div className="d-inline-flex flex-column">
            <div className="sublogo mx-auto">
            <br />
              We're redefining the standard for low-cost, attritable drones by
              turning $500 drones into $500K drones. Stay tuned for updates!
            </div>
          
            <br />
            <br />
          </div>
        </div>
      </div>
    </Container>
    //
  );
}

export default function Hero() {
  return FullWidthGrid();
}

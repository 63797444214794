import useNavigate from "react";
// import reactLogo from './assets/react.svg'
// import viteLogo from '/vite.svg'
import "@fontsource/silkscreen"; // Defaults to weight 400
//import "@fontsource/roboto"; // Specify weight
import "@fontsource/montserrat/500.css";

// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid2";
import Hero from "./hero";

import AboveTheFold from "./aboveTheFold";
import CallToAction from "./callToAction";
import Hero2 from "./hero2";
import Contact from "./contact";
import Footer from "./footer";

import "./../../css/home.css";
import "@fontsource/unica-one"; // Defaults to weight 400

export default function HomePage() {
  return (
    <>
      {Hero()}
      {/* {Hero2()} */}

      {/* {AboveTheFold()} */}
      {CallToAction()}
      {Contact()}
      {Footer()}
    </>
  );
}

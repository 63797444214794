import React, { useState, Fragment } from "react"; // So it doesn't create a unnecessary node element. **Only available in react 16+

import "@fontsource/unica-one"; // Defaults to weight 400

import Container from "react-bootstrap/Container";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import validator from 'validator';

import "./../../css/home.css";

// import "@fontsource/silkscreen"; // Defaults to weight 400
// //import "@fontsource/roboto"; // Specify weight
// import "@fontsource/montserrat/500.css";

// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid2";
// import "./../../css/home.css";
// import myImage from "../../../../public/images/sky.avif";

function FullWidthGrid() {
  const [inputValue, setInputValue] = useState("");
  const [signUp, setSignUp] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const submitEmail = async () => {
    setSignUp(true);
    if (validator.isEmail(inputValue))
    {
      setIsValid(true);
      try {
        const config = {
          timeout: 5000, // timeout in milliseconds (e.g., 5 seconds)
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
          }
        };
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_API_URL}`, {
          email: inputValue,
          date: new Date(),
        }, config);
        // 
        // Handle success, e.g., clear form fields
      } catch (error) {
        console.error(error);
        // Handle error, e.g., display an error message
      }
    }
  };

  const showSignUp = () => {
    if (signUp & isValid) {
      return <div className="text mx-auto text-success">Sign-up complete!</div>;
    }
    else if (signUp) {
      return <div className="text mx-auto text-danger">Invalid email</div>;
    }
    
  };

  return (
    <Container fluid="true" id="join">
      <div className="row">
        <div className="d-inline-flex flex-column">
          <div className="calltoaction">
            <div className="title mx-auto">Get Notified!</div>
            <div className="text mx-auto">
              Sign-up below to receive updates:
            </div>
            {showSignUp()}
            <div className="homebutton mx-auto">
              <TextField
                className="input"
                id="outlined-number"
                label="Email Address"
                value={inputValue}
                onChange={handleChange}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
              <br></br>
              <Button
                sx={{
                  backgroundColor: "black", // Background color
                  color: "white", // Text color
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
                onClick={submitEmail}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
    //
  );
}

export default function CallToAction() {
  //const [count, setCount] = useState(0)
  return FullWidthGrid();
}
